.switch {
  background-color: var(--primary-color);
}

.header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.pagination {
  text-align: right;
  margin-top: 1rem;
}
